import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.multiple ? "Welke switches zijn van toepassing op deze cursussen?" : "Welke switch is van toepassing op deze cursus?")+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.multiple ? "Voeg de switch toe die je wilt toevoegen aan deze cursussen." : "Voeg de switch toe die je wilt toevoegen aan deze cursus.")+" ")]),_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VSubheader,{domProps:{"textContent":_vm._s('Voeg hier je switches toe')}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VSelect,{attrs:{"items":_vm.CourseSwitches,"item-text":"name","menu-props":{ maxHeight: '400' },"label":"Select","multiple":"","hint":"Klik op switches die je wilt toevoegen","persistent-hint":"","no-data-text":"Geen switches gevonden"},model:{value:(_vm.courseswitches),callback:function ($$v) {_vm.courseswitches=$$v},expression:"courseswitches"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":_vm.courseSwitchToggle}},[_vm._v(" Annuleren ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.multiple ? _vm.AddMultipleCourseSwitches() : _vm.AddCourseSwitch()}}},[_vm._v(" Toevoegen ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }