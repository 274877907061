import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{class:[
        _vm.bottomMargin ? 'page-breadcrumb' : 'page-breadcrumb-no-margin' ]},[_c(VCol,{staticClass:"pt-6",attrs:{"cols":"12","sm":"12"}},[_c(VCard,{staticClass:"px-2 custom-shaodow"},[_c(VToolbar,{staticClass:"transparent pa-0",attrs:{"dense":"","flat":"","height":"40px"}},[_c(VToolbarTitle,{staticClass:"pt-3",class:{ 'pb-2': _vm.$vuetify.breakpoint.mdAndDown }},[_c('span',{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.title))])]),_c(VToolbarTitle,{staticClass:"toobar-extension transparent pb-3",attrs:{"slot":"extension"},slot:"extension"},[_c(VBreadcrumbs,{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([(_vm.breadcrumbs)?{key:"divider",fn:function(){return [_c(VIcon,[_vm._v("mdi-chevron-right")])]},proxy:true}:null],null,true)}),_vm._t("default")],2),_c(VSpacer)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }