import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"slide","mode":"out-in"}},[_c(VContainer,[_c(VTabs,{staticClass:"m-6",attrs:{"centered":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTab,{staticClass:"w-full",on:{"click":function($event){return _vm.navigate('Options')}}},[_vm._v("Opties")]),_c(VTab,{staticClass:"w-full",on:{"click":function($event){return _vm.navigate('Courses')}}},[_vm._v("Cursussen")]),_c(VTab,{staticClass:"w-full",on:{"click":function($event){return _vm.navigate('Teachers')}}},[_vm._v("Docenten")])],1),_c(VTabsItems,{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('router-view')],1),_c('div',{staticClass:"mb-6"},[_vm._v(" Active picker: "),_c('code',[_vm._v(_vm._s(_vm.activePicker || "null"))])]),_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Cursus datum","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"active-picker":_vm.activePicker,"max":new Date(
                            Date.now() -
                                new Date().getTimezoneOffset() * 60000
                        )
                            .toISOString()
                            .substr(0, 10),"min":"1950-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.goBack}},[_vm._v(" Ga terug ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }