/*=========================================================================================
  File Name: courses.js
  Description: Vuex store for content
==========================================================================================*/
//imports
import axios from "axios";

// initial state
const state = {
  courseDates: [],
  fullCourseDates: [],
  unansweredCourseDates: [],
  courseTokenObject: {},
  courseRequestEvent: [],
  definitiveId: [],
  currentOverview: {},
};

// getters
const getters = {
  getCourseDates: (state) => {
    return state.courseDates;
  },
  getFullCourseDates: (state) => {
    return state.fullCourseDates;
  },
  getCourseTokenObject: (state) => {
    return state.courseTokenObject;
  },
  getUnansweredCourseDates: (state) => {
    return state.unansweredCourseDates;
  },
  getCourseRequestEvents: (state) => {
    return state.courseRequestEvent;
  },
  getDefinitiveId: (state) => {
    return state.definitiveId;
  },
  getCurrentOverview: (state) => {
    return state.currentOverview;
  },
};

// actions
const actions = {
  //setSpecificDateState
  setSpecificDateState({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/CourseDateRequests/setState/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("EDIT_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setSpecificDateStates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/setStates", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("EDIT_MULTIPLE_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //setSpecificTime
  setSpecificTime({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("api/CourseDateRequests/setTime/" + payload.id, payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("EDIT_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCourseDatesByTeacher({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseDateRequests/teacher/" + payload.teacherId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCourseDatesByTeacherList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/teacherlist", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_FULL_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  postCourseDate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("ADD_COURSEDATEREQUEST", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  postNewTeacherCourseDates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/newTeachers", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveSelectComponent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/selectcomponents", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 250);
          commit("ADD_COURSEDATE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  requestTeacherDates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/requestTeacherDates", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEREQUESTEVENT_OBJECT", {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCourseByToken({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseDateRequests/fetchCourseByToken/" + payload.token)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEDATES_OBJECT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editDateType({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          "api/CourseDateRequests/" +
            payload.id +
            "/" +
            payload.type +
            "/" +
            payload.description
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("EDIT_TEACHER_DATE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  postTeacherCourseDates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/Teachers", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  postCustomCourseDate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/custom/", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("ADD_COURSEDATEREQUEST", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  postCustomCourseDate2({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/custom2", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("ADD_COURSEDATEREQUEST", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCourseDatesByToken({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseDateRequests/token/" + payload.token)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEDATES_OBJECT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //GET course request events
  fetchCourseRequestEvents({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseRequestEvents/")
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEREQUESTEVENT_OBJECT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCourseRequestEvent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseRequestEvents/" + payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEREQUESTEVENT_OBJECT", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  postCourseDateRequestResponse({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/sendTeacherResponse/", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("POST_COURSEDATES_RESPONSE", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Planner dates view
  fetchUnansweredCourseDates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseDateRequests/unanswered/" + payload.courseRequestId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_UNANSWERED_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
          commit("FETCH_UNANSWERED_COURSEDATES", []);
        });
    });
  },
  postCourseDateRequestResponsePlanning({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/sendPlanningResponse/", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_UNANSWERED_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchCourseDatesPlanning({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/CourseDateRequests/planning/" + payload.courseRequestId)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
          commit("FETCH_COURSEDATES", []);
        });
    });
  },
  postCourseDatesCompany({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/company", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteTeacherDates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          "api/CourseDateRequests/TeacherDates/" +
            payload.id +
            "/" +
            payload.psTeacherId
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("REMOVE_TEACHER_DATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //removeCourseDates
  removeCourseDates({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "api/CourseDateRequests/RemoveDates/" + payload.courseRequestId,
          payload
        )
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          let commitLoad = {
            dates: res.data,
            active: payload.remove,
          };
          commit("REMOVE_COURSE_DATES", commitLoad);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  postCourseDateDefinitive({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/definitive", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEDATES", res.data.courseDates);
          commit("FETCH_DEFINITIVE_ID", res.data.courseIds);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //temp storage
  setCurrentOverview({ commit }, payload) {
    commit("SET_CURRENT_OVERVIEW", payload);
  },
  postRequestDatesTeacher({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/CourseDateRequests/requestdates/teacher", payload)
        .then((res) => {
          setTimeout(() => resolve(res.data), 200);
          commit("FETCH_COURSEDATES", res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  FETCH_COURSEDATES(state, data) {
    state.courseDates = data;
  },
  FETCH_FULL_COURSEDATES(state, data) {
    state.fullCourseDates = data;
  },
  ADD_COURSEDATEREQUEST(state, data) {
    state.courseDates = [...state.courseDates, ...data];
  },
  ADD_COURSEDATE(state, data) {
    let removeId = data.removeId;
    if (removeId != null) {
      let index = state.courseDates.findIndex(
        (element) => element.id == removeId
      );

      //delete old
      state.courseDates.splice(index, 1);

      state.courseDates.splice(index, 0, ...data.courseDateRequests);
    } else {
      data.courseDateRequests.forEach((element) => {
        state.courseDates = [
          ...state.courseDates.map((item) =>
            item.id !== element.id ? item : { ...item, ...element }
          ),
        ];
      });
    }
  },
  FETCH_COURSEDATES_OBJECT(state, data) {
    state.courseTokenObject = data;
  },
  FETCH_COURSEREQUESTEVENT_OBJECT(state, data) {
    state.courseRequestEvent = data;
  },
  POST_COURSEDATES_RESPONSE(state) {
    state.courseTokenObject = {};
  },
  FETCH_UNANSWERED_COURSEDATES(state, data) {
    state.unansweredCourseDates = data;
  },
  FETCH_DEFINITIVE_ID(state, data) {
    state.definitiveId = data;
  },
  SET_CURRENT_OVERVIEW(state, data) {
    state.currentOverview = data;
  },
  EDIT_TEACHER_DATE(state, data) {
    state.courseDates = [
      ...state.courseDates.filter((element) => element.id !== data.id),
      data,
    ];
  },
  EDIT_COURSEDATES(state, data) {
    //update state without changing order
    state.courseDates = [
      ...state.courseDates.map((item) =>
        item.id !== data.id ? item : { ...item, ...data }
      ),
    ];
  },
  EDIT_MULTIPLE_COURSEDATES(state, data) {
    data.forEach((element) => {
      state.courseDates = [
        ...state.courseDates.map((item) =>
          item.id !== element.id ? item : { ...item, ...element }
        ),
      ];
    });
  },
  REMOVE_TEACHER_DATES(state, data) {
    let index = state.courseDates.findIndex((element) => element.id == data.id);
    state.courseDates.splice(index, 1);
  },
  REMOVE_COURSE_DATES(state, data) {
    data.dates.forEach((element) => {
      let index = state.courseDates.findIndex((x) => x.id == element);
      let newObj = { ...state.courseDates[index] };

      newObj.active = !data.active;

      state.courseDates = [
        ...state.courseDates.filter((y) => y.id !== element),
        newObj,
      ];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
