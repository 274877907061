import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('div',[_c(VRow,[_c(VCol,{staticClass:"pa-0 pa-md-1",attrs:{"cols":"12","sm":"12"}},[_c('BaseCard',{attrs:{"heading":"Archief"}},[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"rounded":"","color":"primary","outlined":""},on:{"click":_vm.goBack}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Terug ")],1)],1)],1),_c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.deleted,"search":_vm.search,"custom-filter":_vm.filterText,"loading-text":"De data wordt geladen..","show-group-by":"","group-by":"trainingName","item-key":"name","sort-by":"name","footer-props":{
                  'items-per-page-text': 'Aantal per pagina',
                  'items-per-page-options': [10, 25, 50, 100, -1],
                },"no-results-text":"Geen resultaten gevonden","items-per-page":50},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.$vuetify.breakpoint.name != 'xs')?_c('div',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e()])]}},{key:"item.companyName",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.$vuetify.breakpoint.name != 'xs')?_c('div',[_c('span',[_vm._v(_vm._s(item.companyName))])]):_vm._e()])]}},{key:"item.city",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.$vuetify.breakpoint.name != 'xs')?_c('div',[_c('span',[_vm._v(_vm._s(item.city))])]):_vm._e()])]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.$vuetify.breakpoint.name != 'xs')?_c('div',[_c('span',[_vm._v(_vm._s(item.amount))])]):_vm._e()])]}},{key:"item.requestDate",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"subtitle-2 font-weight-regular mr-2"},[_vm._v(_vm._s(_vm._f("regularDate")(item.requestDate)))]),(_vm.$vuetify.breakpoint.name != 'xs')?_c(VChip,{attrs:{"color":"primary","label":""}},[_vm._v(_vm._s(_vm._f("time")(item.requestDate)))]):_vm._e()]}},{key:"item.birthday",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"subtitle-2 d-block font-weight-regular"},[_vm._v(_vm._s(_vm._f("regularDate")(item.birthday)))])]}},{key:"item.dateValidTeacher",fn:function(ref){
                var item = ref.item;
return [(_vm.$vuetify.breakpoint.name != 'xs')?_c(VAvatar,{attrs:{"color":!item.dateValidTeacher ? 'error' : 'success',"size":"30"}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(!item.dateValidTeacher ? "mdi-close" : "mdi-check"))])],1):_c('span',[_c(VIcon,{attrs:{"color":!item.dateValidTeacher ? 'error' : 'success'}},[_vm._v(_vm._s(!item.dateValidTeacher ? "mdi-close" : "mdi-check"))])],1)]}},{key:"item.dateValidCompany",fn:function(ref){
                var item = ref.item;
return [(_vm.$vuetify.breakpoint.name != 'xs')?_c(VAvatar,{attrs:{"color":!item.dateValidCompany ? 'error' : 'success',"size":"30"}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(!item.dateValidCompany ? "mdi-close" : "mdi-check"))])],1):_c('span',[_c(VIcon,{attrs:{"color":!item.dateValidCompany ? 'error' : 'success'}},[_vm._v(_vm._s(!item.dateValidCompany ? "mdi-close" : "mdi-check"))])],1)]}},{key:"no-data",fn:function(){return [_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true},{key:"group.header",fn:function(ref){
                  var group = ref.group;
                  var headers = ref.headers;
                  var toggle = ref.toggle;
                  var isOpen = ref.isOpen;
                  var remove = ref.remove;
                  var groupBy = ref.groupBy;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},'v-btn',attrs,false),on),[(isOpen)?_c(VIcon,[_vm._v("mdi-plus")]):_c(VIcon,[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Klik om te "+_vm._s(isOpen ? "minimaliseren" : "maximaliseren"))])]),_c('span',{staticClass:"ml-3 mr-1"},[_vm._v(_vm._s(_vm.getHeaderByValue(groupBy[0]).text)+":")]),_c('span',{staticClass:"mr-3 font-weight-medium"},[_vm._v(_vm._s(group))]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({ref:group,attrs:{"x-small":"","icon":""},on:{"click":remove}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-close-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Klik om de groepering stop te zetten.")])])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }